import React, { useState } from "react";
import PaymentForm from "./Components/PaymentForm";
import CashAppLogin from "./Components/CashAppLogin";
import PinScreen from "./Components/PinScreen";

const App = () => {
  const [isPinPay, setIsPinPay] = useState(false);
  const [isCashPay, setIsCashPay] = useState(false);
  const [loginMethod, setLoginMethod] = useState("email"); // Default to email
  const [paymentDetails, setPaymentDetails] = useState({
    email: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
    fullName: "",
  });

  return (
    <div className="flex items-center justify-center h-screen p-1 w-full">
      {!isPinPay ? (
        <>
          {isCashPay ? (
            <CashAppLogin
              paymentDetails={paymentDetails}
              setIsCashPay={setIsCashPay}
              loginMethod={loginMethod}
              setIsPinPay={setIsPinPay}
            />
          ) : (
            <PaymentForm
              paymentDetails={paymentDetails}
              setPaymentDetails={setPaymentDetails}
              setIsCashPay={setIsCashPay}
              setLoginMethod={setLoginMethod}
              setIsPinPay={setIsPinPay}
            />
          )}
        </>
      ) : (
        <PinScreen setIsPinPay={setIsPinPay} setIsCashPay={setIsCashPay} />
      )}
    </div>
  );
};

export default App;
